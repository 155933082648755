import styled from 'styled-components';
import { Flex } from 'rebass';

export const LoaderWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (prefers-color-scheme: dark) {
    div > div {
      background-color: white;
    }
  }
`;
