import React from 'react';
import GridLoader from 'react-spinners/GridLoader';

import { LoaderWrapper } from './styles';

const Loader = () => (
  <LoaderWrapper>
    <GridLoader size={48} margin="8px" />
  </LoaderWrapper>
);

export default React.memo(Loader);
