import React from 'react';
import { withTranslation } from 'react-i18next';
import Ink from 'react-ink';
import { Button, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { height } from 'styled-system';

const SubHeader = styled(Flex).attrs(({ theme }) => ({
  color: theme.textLight,
}))`
  ${height}
  overflow: hidden;
  transition: height 1s;
`;

const SubHeaderButtonWrapper = styled(Flex).attrs({
  flex: 1,
  alignItems: 'flex-end',
})`
  position: relative;
  text-align: center;
`;

const ActiveTabBorder = styled.div`
  border-bottom: 8px solid transparent;
  border-bottom-color: ${props => (props.active ? 'white' : 'transparent')};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

const actions = ['doors', /*'concierges', */ 'profile'];

const SubheaderBar = ({ loading, open, onAction, t }) => {
  return (
    <SubHeader height={[open ? 42 : 0, !loading ? 'auto' : 0]}>
      {actions.map(a => {
        const isActive = a === 'doors';
        return (
          <SubHeaderButtonWrapper key={a}>
            <Button disabled={isActive} variant="link" onClick={() => onAction(a)}>
              <Text>{!loading ? t(a) : ' '}</Text>
              {!isActive && <Ink />}
            </Button>
            <ActiveTabBorder active={isActive} />
          </SubHeaderButtonWrapper>
        );
      })}
    </SubHeader>
  );
};

export default withTranslation()(React.memo(SubheaderBar));
