import { differenceInDays, set } from 'date-fns';

export const Colors = {
  main: '#282828',
  textLight: '#F2F2F2',
  textDark: '#333',
  actionMain: '#4D9AB9',
  actionSuccess: '#62CA62',
  actionTimeout: '#923030',
  white: 'white',
};

const BaseButton = {
  fontFamily: 'Helvetica Neue',
  fontSize: '2rem',
  maxWidth: '100%',
  minWidth: 200,
  outline: 'none',
  padding: 20,
  position: 'relative',
};

export default {
  ...Colors,
  xmas: Math.abs(differenceInDays(set(Date.now(), { date: 25, month: 11 }), Date.now())) < 10,
  buttons: {
    login: {
      ...BaseButton,
      backgroundColor: Colors.white,
      boxShadow: 'inset 0 0 0 2px',
      color: Colors.textDark,
    },
    link: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      fontFamily: 'Helvetica Neue',
      fontSize: '1rem',
      outline: 'none',
      padding: 10,
      position: 'relative',
      width: '100%',
    },
    open: {
      ...BaseButton,
      backgroundColor: Colors.actionMain,
      color: Colors.white,
      minHeight: 200,
    },
    openProgress: {
      ...BaseButton,
      backgroundColor: Colors.actionSuccess,
      color: Colors.white,
      minHeight: 200,
    },
    timedOut: {
      ...BaseButton,
      backgroundColor: Colors.actionTimeout,
      color: Colors.white,
      minHeight: 200,
    },
  },
};
