import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Ink from 'react-ink';
import { Button, Flex } from 'rebass';

import { useCachedKeycloak } from '../../utils';

import DoorsList from './DoorsList';

const HomePage = () => {
  const { t } = useTranslation();
  const [keycloak, { authenticated }] = useCachedKeycloak();
  const onLogin = useCallback(() => keycloak.login(), [keycloak]);

  if (!authenticated) {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center">
        <Button fontWeight="bold" variant="login" onClick={onLogin}>
          {t('login')}
          <Ink />
        </Button>
      </Flex>
    );
  }

  return <DoorsList />;
};

export default React.memo(HomePage);
