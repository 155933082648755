import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './app';
import * as serviceWorker from './serviceWorker';

import './index.css';

Sentry.init({
  debug: process.env.NODE_ENV === 'development',
  dsn: 'https://2d2740793a6e484183d8dad2a01ce39c@sentry.mikamai.com/8',
  environment: process.env.REACT_APP_SENTRY_CURRENT_ENV,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// Orientation lock for iOS
if (/^iP(hone|[ao]d)/.test(navigator.platform)) {
  // Add ios class to body
  document.body.classList.add('ios');

  // Define OrientationChange handler
  function deviceOrientation() {
    var body = document.body;
    switch (window.orientation) {
      case 90:
        body.classList.remove('portrait');
        body.classList.remove('rotation-90');
        body.classList.add('rotation90');
        break;

      case -90:
        body.classList.remove('portrait');
        body.classList.remove('rotation90');
        body.classList.add('rotation-90');
        break;

      default:
        body.classList.remove('rotation90');
        body.classList.remove('rotation-90');
        body.classList.add('portrait');
        break;
    }
  }

  // Attach OrientationChange listener
  window.addEventListener('orientationchange', deviceOrientation);
  // Startup check
  deviceOrientation();
}
