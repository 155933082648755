import styled, { css } from 'styled-components';
import { Box, Flex } from 'rebass';

export const DoorsListContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
`;

export const DoorCardButtonWrapper = styled(Box)`
  border-radius: 10px;
  margin: 5px;
  padding: 3px;

  ${props =>
    props.theme.xmas
      ? css`
          background: repeating-linear-gradient(
            135deg,
            #f2f2f2,
            #f2f2f2 30px,
            #ff0800 30px,
            #ff0800 50px
          );
          border: 1px solid #ff0800;
        `
      : undefined}
`;

export const EmptyDoorsListContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (prefers-color-scheme: dark) {
    h2,
    div,
    span {
      color: white;
    }

    svg {
      fill: white;
    }
  }
`;
