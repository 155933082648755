import React from 'react';
import Ink from 'react-ink';
import BeatLoader from 'react-spinners/BeatLoader';
import { Button, Text } from 'rebass';

import { OPEN_DOOR } from '../../../graphql';
import { Colors } from '../../../theme';
import { useTimedMutation } from '../../../utils';
import { DoorCardButtonWrapper } from './styles';

const getButtonVariant = (loading, error, timedOut) => {
  if (timedOut || error) {
    return 'timedOut';
  }

  if (loading) {
    return 'openProgress';
  }

  return 'open';
};

const DoorCard = ({ door }) => {
  const { displayName, name } = door || {};
  const [openDoor, { error, loading, timedOut }] = useTimedMutation(
    OPEN_DOOR,
    {
      variables: {
        doorId: name,
      },
      refetchQueries: ['Doors'],
    },
    2000
  );

  return (
    <DoorCardButtonWrapper width={[1, 1, 1 / 2]} py={2}>
      <Button
        key={name}
        variant={getButtonVariant(loading, error, timedOut)}
        disabled={loading}
        onClick={openDoor}
        borderRadius={8}
        boxShadow="0 2px 16px rgba(0, 0, 0, 0.25)"
        width={1}
      >
        {loading ? <BeatLoader color={Colors.white} /> : <Text>{displayName || name}</Text>}
        <Ink />
      </Button>
    </DoorCardButtonWrapper>
  );
};

export default React.memo(DoorCard);
