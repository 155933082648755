import { complement, filter, flatten, head, isNil, path, pick, pipe, values } from 'rambda';

export * from './hooks';

export const getUsernameFor = ({ firstName, lastName }) =>
  `${firstName || ''} ${lastName || ''}`.trim();

export const extractErrorObject = pipe(
  pick(['fetchError', 'httpError', 'graphQLErrors']),
  values,
  flatten,
  filter(complement(isNil)),
  head
);

export const getGraphQLErrorMessage = pipe(extractErrorObject, path('message.error'));
