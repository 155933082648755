import React, { Suspense, Fragment } from 'react';
import { KeycloakProvider } from '@react-keycloak/web';
import Notifications from 'react-notify-toast';
import Keycloak from 'keycloak-js';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/browser';

import GraphQLProvider from './components/GraphQLProvider';
import HeaderBar from './components/HeaderBar';
import Loader from './components/Loader';
import './i18n';
import HomePage from './pages/Home';
import { AppContainer } from './styles';
import theme from './theme';

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

const keycloakInitConfig = {
  onLoad: 'login-required',
};

const keycloakErrorHandler = (event, error) => {
  if (error) {
    Sentry.captureMessage(error);
  }
};

const LoadingScreen = React.memo(() => <Loader />);

const App = () => (
  <ThemeProvider theme={theme}>
    <AppContainer>
      <Notifications />
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={keycloakInitConfig}
        onError={keycloakErrorHandler}
        LoadingComponent={<LoadingScreen />}
      >
        <Suspense fallback={<LoadingScreen />}>
          <GraphQLProvider>
            <Fragment>
              <HeaderBar />
              <HomePage />
            </Fragment>
          </GraphQLProvider>
        </Suspense>
      </KeycloakProvider>
    </AppContainer>
  </ThemeProvider>
);

export default React.memo(App);
