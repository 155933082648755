import { useCallback, useEffect, useRef, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { notify } from 'react-notify-toast';
import { useLocalStorageState } from 'react-storage-hooks';
import { useMutation } from 'graphql-hooks';
import { omit } from 'rambda';

import * as Sentry from '@sentry/browser';

export const useCachedKeycloak = () => {
  const [keycloak, initialized] = useKeycloak();
  const [cachedKeycloak, setCachedKeycloak] = useLocalStorageState('cachedKC');

  useEffect(() => {
    if (initialized) {
      setCachedKeycloak({
        authenticated: keycloak.authenticated,
        token: keycloak.token,
      });
    }
  }, [initialized, keycloak.authenticated, keycloak.token, setCachedKeycloak]);

  return [
    keycloak,
    {
      authenticated: false,
      token: null,
      ...cachedKeycloak,
    },
  ];
};

const setSentryUser = userData => {
  Sentry.configureScope(scope => {
    scope.setUser(omit(['attributes', 'emailVerified'], userData));
  });
};

export const useKeycloakUserProfile = () => {
  const [keycloak, keycloakInitialized] = useKeycloak();
  const [userData, setUserData] = useLocalStorageState('userData');
  const { i18n } = useTranslation(null, { useSuspense: false });

  useEffect(() => {
    if (keycloakInitialized) {
      if (!keycloak.authenticated) {
        setUserData({});
        setSentryUser({});
        return;
      }

      keycloak
        .loadUserProfile()
        .then(userProfile => {
          setUserData(userProfile);

          setSentryUser(userProfile);

          i18n.changeLanguage(userProfile?.attributes?.locale?.[0] ?? 'en');
        })
        .catch(err => {
          console.error('Failed to load user profile', err);
        });
    }
  }, [keycloakInitialized, keycloak.authenticated, i18n, keycloak, setUserData]);

  return userData || {};
};

export const useErrorNotification = error => {
  useEffect(() => {
    if (error) {
      notify.show(error, 'error');
    }
  }, [error]);
};

export function useToggle(initialState = false) {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(state => !state), []);

  return [state, toggle];
}

export function useTimeout(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    if (delay !== null) {
      const id = setTimeout(savedCallback.current, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
}

function timeoutPromise(timeout, value) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(value);
    }, timeout);
  });
}

export function useTimedMutation(mutationQuery, mutationParams, timeout) {
  const [executeMutation, { error, loading }] = useMutation(mutationQuery, mutationParams);
  const [timedOut, setTimedOut] = useState(false);
  const [localError, setLocalError] = useState(null);

  const launchMutation = useCallback(() => {
    setLocalError(null);
    setTimedOut(false);

    Promise.race([executeMutation(), timeoutPromise(timeout, 'false')])
      .then(res => {
        setTimedOut(res === 'false');
      })
      .catch(err => {
        setLocalError(err);
      });
  }, [executeMutation, timeout]);

  return [
    launchMutation,
    {
      error: error || localError,
      loading: loading && !timedOut,
      timedOut,
    },
  ];
}
