import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from 'rebass';

import NoDoor from '../../../assets/NoDoor';

import { EmptyDoorsListContainer } from './styles';

const EmptyDoorsList = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <EmptyDoorsListContainer onClick={onClick}>
      <NoDoor />
      <Heading style={{ cursor: 'default' }}>{t('no door')}</Heading>
      <Text style={{ cursor: 'pointer' }}>{t('press to refresh')}</Text>
    </EmptyDoorsListContainer>
  );
};

export default React.memo(EmptyDoorsList);
