import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { Flex, Text } from 'rebass';
import styled from 'styled-components';
import { display } from 'styled-system';

import { getUsernameFor } from '../utils';
import Arrow from '../assets/arrow';

const UserName = styled(Text).attrs(({ theme }) => ({
  color: theme.textLight,
}))`
  ${display}
`;

const AnimatedArrow = styled(Arrow)`
  ${display}
  margin-left: 5px;
  transform: rotateX(${({ open }) => (open ? '0' : '180')}deg);
  transition: all 0.75s;
`;

const UserInfo = ({ onAvatarClick, open, user }) => {
  const { email, firstName, lastName } = user;

  if (!email && !firstName && !lastName) {
    return null;
  }

  const userName = getUsernameFor(user);
  return (
    <Flex flexDirection="row" alignItems="center" onClick={onAvatarClick}>
      <Avatar name={userName} email={email} size={48} round />

      <UserName display={['none', 'block']} fontWeight="500" ml={2}>
        {userName}
      </UserName>

      <AnimatedArrow display={['block', 'none']} height={12} width={12} open={open} />
    </Flex>
  );
};

UserInfo.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default React.memo(UserInfo);
