import React, { Suspense } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Flex, Heading as _heading } from 'rebass';
import styled from 'styled-components';
import { height } from 'styled-system';

import { useKeycloakUserProfile, useToggle } from '../utils';

import UserInfo from './UserInfo';
import SubheaderBar from './SubheaderBar';

const Header = styled.header`
  background-color: ${({ theme }) => theme.main};

  /* Status bar height on iOS 11.0 */
  padding-top: max(constant(safe-area-inset-top), const(safe-area-inset-right));
  /* Status bar height on iOS 11+ */
  padding-top: max(env(safe-area-inset-top), env(safe-area-inset-right));
`;

const HeaderContainer = styled(Flex).attrs(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 70,
  pl: 20,
  pr: 10,
}))`
  ${height}
`;

const Heading = styled(_heading).attrs(({ theme }) => ({
  color: theme.textLight,
}))``;

const HeaderBar = () => {
  const { keycloak } = useKeycloak();
  const user = useKeycloakUserProfile();
  const [subheaderOpen, toggleSubheaderOpen] = useToggle(false);

  const actionHandler = action => {
    if (action === 'profile') keycloak.accountManagement();
  };

  return (
    <Header>
      <HeaderContainer>
        <Heading>APICancello</Heading>
        <UserInfo onAvatarClick={toggleSubheaderOpen} open={subheaderOpen} user={user} />
      </HeaderContainer>

      <Suspense fallback={null}>
        <SubheaderBar
          loading={!keycloak.authenticated}
          onAction={actionHandler}
          open={subheaderOpen}
        />
      </Suspense>
    </Header>
  );
};

export default React.memo(HeaderBar);
