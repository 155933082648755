import React, { useCallback } from 'react';
import { useQuery } from 'graphql-hooks';

import Loader from '../../../components/Loader';
import { GET_DOORS } from '../../../graphql';

import DoorCard from './DoorCard';
import EmptyDoorsList from './EmptyDoorsList';
import { DoorsListContainer } from './styles';

const DoorList = () => {
  const { data, loading, refetch } = useQuery(GET_DOORS);
  const onRefetch = useCallback(() => refetch(), [refetch]);

  if (loading) {
    return <Loader />;
  }

  const doors = data?.doors ?? [];
  return (
    <DoorsListContainer>
      {doors.length !== 0 ? (
        doors.map(d => <DoorCard key={d.name} door={d} />)
      ) : (
        <EmptyDoorsList onClick={onRefetch} />
      )}
    </DoorsListContainer>
  );
};

export default React.memo(DoorList);
