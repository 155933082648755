import { Flex } from 'rebass';
import styled, { css } from 'styled-components';
import { height } from 'styled-system';

export const AppContainer = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'column',
  height: '100%',
})`
  ${height}

  ${props =>
    props.theme.xmas
      ? css`
          background-image: url('/assets/xmas-bg.png');
        `
      : undefined}

  @media (prefers-color-scheme: dark) {
    background-color: black;
  }
`;
