export const GET_DOORS = `
  query Doors {
    doors {
      name
      displayName
    }
  }
`;

export const OPEN_DOOR = `
  mutation doorOpen($doorId: String!) {
    doorOpen(name: $doorId) {
      name
      status
    }
  }
`;

export const OPEN_DOORS = `
  mutation DoorOpenAll {
    doorOpenAll {
      name
      status
    }
  }
`;
