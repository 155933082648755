import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClientContext, GraphQLClient } from 'graphql-hooks';
import { useKeycloak } from '@react-keycloak/web';
import { notify } from 'react-notify-toast';

import { extractErrorObject } from '../utils';

const GraphQLProvider = ({ children }) => {
  const { keycloak } = useKeycloak();

  const graphQLClient = new GraphQLClient({
    url: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    },
    logErrors: process.env.NODE_ENV === 'development',
    onError: ({ result, operation }) => {
      const error = extractErrorObject(result.error);
      if (error) {
        notify.show(error?.message?.error, 'error');

        if (error?.message?.statusCode === 401) {
          keycloak.logout();
        }
      }
    },
  });

  useEffect(() => {
    graphQLClient.setHeader(
      'Authorization',
      !!keycloak.token ? `Bearer ${keycloak.token}` : undefined
    );
  }, [graphQLClient, keycloak.token]);

  return (
    <ClientContext.Provider value={graphQLClient}>
      {React.Children.only(children)}
    </ClientContext.Provider>
  );
};

GraphQLProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default React.memo(GraphQLProvider);
